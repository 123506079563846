import React from 'react';
import { injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import {LabelLang, WrapperLang, HtmlLang} from 'src/lang/components';
import flagUs from './img/flag_us.svg';
import flagHk from './img/flag_hk.svg';
import flagVn from './img/flag_vn.svg';
import flagMalta from './img/flag_malta.svg';
import { ListLinkContainerStyled } from './styled';

const getIntlKey = name => `footer.${name}`;

export const data = {
  us: {
    flag: flagUs,
    showTime: true
  },
  // hk: {
  //   flag: flagHk,
  //   showTime: false
  // },
  // vn: {
  //   flag: flagVn,
  //   showTime: false
  // },
  // malta: {
  //   flag: flagMalta,
  //   showTime: false
  // },
};

export const StyledContainer = styled(ListLinkContainerStyled)`
  margin-top: 20px;
  margin-left:-20px;
  padding: 10px 20px;
  background-image: linear-gradient(270deg, #FFFFFF 0%, #F8FAFD 100%);
  border: 1px solid var(--border);
  border-radius: 15px;
  ${props => props.theme === 'dark' && css`
    background-image: linear-gradient(270deg, #000 0%, #34384c 100%);
  `}
  h3 {
    font-size: 10px;
    color: var(--text-primary);
    margin-bottom: 0;
    text-transform: uppercase;
    line-height: 10px;
  }
  .link {
    line-height: 16px;
    font-size: 11px;
    a {
      color: var(--text-primary);
      font-size: 14px;
      line-height: 30px;
      font-weight: bold;
    }

    img {
      width: 15px;
      height: 11px;
    }
  }
`;

const ContactUs = ({ theme }) => {
  return null;
  return (
    <StyledContainer theme={theme}>
      <h3>
        <LabelLang id={getIntlKey('contactUs')}/>
      </h3>
      <div>
        {Object.keys(data)
          .map(key => {
            const text = `phone.${key}.phone`;
            const {
              flag,
              showTime,
            } = data[key];
            return (
              <div className="link" key={key}>
                <img alt="" src={flag}/>
                <WrapperLang>
                  {ts => <a href={`tel:${ts(getIntlKey(text))}`}><LabelLang id={getIntlKey(text)}/></a>}
                </WrapperLang>
                {showTime && <div className="time"><HtmlLang id={getIntlKey(`phone.${key}.time`)}/></div>}
              </div>
            );
          })}
      </div>
    </StyledContainer>
  );
};

export default injectIntl(ContactUs);
