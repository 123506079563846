import React from 'react';
import styled from 'styled-components';

const ContentStyled = styled.div`
  padding: 30px 0;
  
  .content {
    display: grid;
    grid-template-columns: 1fr 0.1fr 1fr;
  }
   
  p, a, a:hover {
    font-family: SFMono;
    font-size: 10px;
    line-height: 18px;
    color: inherit;
  }

  a {
    text-decoration: underline;
  }
  
  @media screen and (max-width: 768px) {
    background: unset;
    border-top: none;
    
    >div:first-child {
     padding-top: unset;
     padding-bottom: unset;
   }
   
   .content {
    grid-template-columns: 1fr;
   }
  }
`;

const Content = () => (
  <ContentStyled>
    <div className="content">
      <div>
        <p><strong>How does MyConstant investing work?</strong></p>
        <p>When you <a target="_blank" href="https://www.myconstant.com/deposit-money-online">invest with us</a>, you first send your funds to our custodial partner Prime Trust, an accredited US financial institution.</p>
        <p>We then lend on your behalf to collateralized borrowers, liquidity pools, and decentralized exchanges in return for interest.</p>
        <p>When you’re ready to <a target="_blank" href="https://www.myconstant.com/withdraw">withdraw</a>, Prime Trust will process your withdrawal request. There is no charge or fees for fiat (USD etc) withdrawals. You'll keep the interest you earn up to the moment you withdraw your funds.</p>
        <p>You can choose our <a target="_blank" href="https://www.myconstant.com/deposit-money-online">instant access account</a> or <a target="_blank" href="https://www.myconstant.com/lend-money-online-to-earn-interest">invest for a fixed term</a>. Our instant access account has a 24-hour term that’s automatically extended every 24 hours unless you choose to disable the product or <a target="_blank" href="https://www.myconstant.com/withdraw">withdraw your funds</a>.</p>
        <p>All account statements for MyConstant investment products are stated in USD equivalent terms at all times.</p>
      </div>
      <div />
      <div>
        <p><strong>Is CONST LLC a bank?</strong></p>
        <p>Please note that CONST LLC (hereafter referred to as “we”, “us”, or “Company”) is not a bank or deposit account, nor is it a regulated financial institution. No interest will be paid on any funds or other assets held in your MyConstant account and all assets directly held by us are not insured by us or, except as set forth below, any third party or any government agency.</p>
        <p>We work with independent third-party financial institutions such as Prime Trust to provide cash management for the <a target="_blank" href="https://www.myconstant.com/deposit-money-online">fiat deposits</a>. When not on loan, the fiat deposits are held in one or more depository accounts at US banks or trust companies whose deposits may be insured.</p>
      </div>
    </div>
  </ContentStyled>
);

export default Content;
