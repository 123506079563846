import styled, { css } from 'styled-components';

import { SPACING_HORIZONTAL } from 'src/assets/themes/spacing';
import { TOP_BANNER_HEIGHT } from '../../topBanner';

export const FooterStyled = styled.div`
  background-color: var(--background);
  padding: 30px ${SPACING_HORIZONTAL}px 60px;
  .branding {
    display: flex;
    flex-direction: column;
  }
  .reward {
    margin-top: 30px;
    .semiBold {
      font-size: 14px;
      display: block;
      margin-bottom: 5px;
    }
    br {
      margin-bottom: 0px;
    }
  }
  .termAndAgreement {
    border-bottom: 1px solid #ECEDED;
    border-top: 1px solid #ECEDED;
    padding: 10px 0px;
    color: var(--text-secondary);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
    > div {
      margin: 5px 0px;
    }
    a {
      font-size: 14px;
      line-height: 24px;
      color: var(--text-primary);
    }
  }
  .mb-footer-content {
    margin-top: 20px;
    margin-bottom: 20px;
    
    &.hasBanner {
      padding-bottom: ${TOP_BANNER_HEIGHT}px;
    }
  }
  .mb-footer-follow {
    border-bottom: 1px solid #ECEDED;
    padding: 15px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 0px;
    }
  }
`;

export const StyledMenuContainer = styled.div`
  margin: 40px 0px 20px 0px;
  
  .StyledMenuItem {
    border: unset;
    border-bottom: 1px solid var(--border);
    padding: 10px 0px;
    background-color: var(--background);
    .title {
      color: var(--text-primary);
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0px;
    }
    .StyledMenuBody {
      padding: unset;
      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        display: block;
        img {
          margin-right: 6px;
        }
      }
      > div {
        margin-top: 15px;
        p,
        span {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const StyledMenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: unset;
  background-color: var(--background);

  &::after {
    display: none;
  }

  &:not(.collapsed) {
    background-color: var(--background);
    box-shadow: unset;
  }
`;

export const StyledIconArrow = styled.img`
  width: 15px;
  height: 15px;
  transition: transform 0.3s ease-in-out;
  transform: ${props => props.isActive ? 'rotate(45deg)' : 'rotate(0deg)'};
`;

export const StyledContactUs = styled.div`
  background-image: linear-gradient(270deg, #FFFFFF 0%, #F8FAFD 100%);
  border: 1px solid var(--background);
  border-radius: 15px 0 0 15px;
  display: flex;
  flex-direction: column;
  padding: 5px 30px 5px 10px;
  margin-right: -20px;
  ${props => props.theme === 'dark' && css`
    background-image: linear-gradient(270deg, #000 0%, #34384c 100%);
  `}
  .phone {
    display: flex;
    font-size: 12px;
    font-weight: bold;
    color: var(--text-primary);
    margin-top: 5px;
    
    img {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
  .time {
    margin-top: 10px;
    p {
      margin-bottom: 0;
    }
  }
`;

export const AppIconWrapper = styled.div`
  .appIcon {
    margin-left: 0px;
  }
  > div {
    font-size: 14px;
  }
`;

export const StyledSupport = styled.div`
  display: flex;
  flex-direction: column;
  > div:first-child {
    padding: 15px 0;
  }
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .link {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    a {
      margin: 2px 0;
    }
    img {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
`;