import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Button} from 'react-bootstrap';
import styled from 'styled-components';

import BlockSection from 'src/components/blocksection';
import { URL } from 'src/resources/constants/url';
import { HtmlLang, LabelLang } from 'src/lang/components';
import ga, { events } from 'src/services/ga';
import BgCta from './img/bg_cta.png';

const scrollToTop = () => {
  window.scroll({top: 0, behavior: 'smooth'});
};

const actionScrollAndRoute = (url, history, props, gaInfo) => {
  const { isAuth } = props;
  if (url) {
    if(gaInfo) {
      ga(gaInfo.category, gaInfo.action, gaInfo.email);
    }

    if (!isAuth) {
      return history.push(URL.USER_SIGN_UP);
    }

    scrollToTop();
    history.push(url);
  }
};

// contents maybe change on difference pages
const CONTENTS = {
  deposit: {
    title: <HtmlLang id="footer.cta.deposit.title" />,
    button: <LabelLang id="footer.cta.deposit.btn" />,
    buttonAction: actionScrollAndRoute.bind(null, URL.DEPOSIT_FIAT),
  },
  signUp: {
    title: <HtmlLang id="footer.cta.signUp.title" />,
    button: <LabelLang id="footer.cta.signUp.btn" />,
    buttonAction: actionScrollAndRoute.bind(null, URL.USER_SIGN_UP),
  },
  invest: {
    title: <HtmlLang id="footer.cta.invest.title" />,
    button: <LabelLang id="footer.cta.invest.btn" />,
    buttonAction: actionScrollAndRoute.bind(null, URL.INVEST),
  },
  borrow: {
    title: <HtmlLang id="footer.cta.borrow.title" />,
    button: <LabelLang id="footer.cta.borrow.btn" />,
    buttonAction: actionScrollAndRoute.bind(null, URL.CRYPTO_LOAN),
    gaInfo: {category: events.category.borrow, action: events.action.borrow_borrownowFooterCTA_button}
  },
  referralFromTransfer: {
    title: <HtmlLang id="footer.cta.invite.title" />,
    button: <LabelLang id="footer.cta.invite.btn" />,
    buttonAction: actionScrollAndRoute.bind(null, URL.REFERRAL),
    id: 'referral_cta_transfer',
    gaInfo: { category: events.category.referralClick, action: events.action.referralClickCTAtransfer }
  },
  referralFromTransferCrypto: {
    title: <HtmlLang id="footer.cta.invite.title" />,
    button: <LabelLang id="footer.cta.invite.btn" />,
    buttonAction: actionScrollAndRoute.bind(null, URL.REFERRAL),
    id: 'referral_cta_transfer_crypto',
    gaInfo: {category: events.category.referralClick, action: events.action.referralClickCTAtransfercrypto}
  },
  referralFromWithdraw: {
    title: <HtmlLang id="footer.cta.invite.title" />,
    button: <LabelLang id="footer.cta.invite.btn" />,
    buttonAction: actionScrollAndRoute.bind(null, URL.REFERRAL),
    id: 'referral_cta_withdraw',
    gaInfo: {category: events.category.referralClick, action: events.action.referralClickCTAwithdraw}
  },
  investSignUp: {
    title: <HtmlLang id="footer.cta.investSignUp.title" />,
    button: <LabelLang id="footer.cta.investSignUp.btn" />,
    buttonAction: actionScrollAndRoute.bind(null, URL.USER_SIGN_UP),
  },
  borrowSignUp: {
    title: <HtmlLang id="footer.cta.borrowSignUp.title" />,
    button: <LabelLang id="footer.cta.borrowSignUp.btn" />,
    buttonAction: actionScrollAndRoute.bind(null, URL.USER_SIGN_UP),
    gaInfo: {category: events.category.borrow, action: events.action.borrow_signupnowCTA_button}
  },
  lendingLotterySignUp: {
    title: <HtmlLang id="footer.cta.signUp.title" />,
    button: <LabelLang id="footer.cta.signUp.btn" />,
    buttonAction: actionScrollAndRoute.bind(null, URL.USER_SIGN_UP),
    gaInfo: {category: events.category.lendingLottery, action: events.action.ll_footerbanner}
  },
};

const getContent = (pathname, isAuth) => {
  if (pathname === URL.REFERRAL || pathname === URL.CRYPTO_LEND_TO_EARN || pathname === URL.MCT_MASCOT || pathname === URL.MCT_MASCOT_TEASER || pathname === URL.PO_FINANCING_LOANS) return null;
  if (!isAuth) {
    switch (pathname) {
      case URL.USER_SIGN_IN:
      case URL.USER_SIGN_UP:
      case URL.AFFILIATES:
      case URL.AFFILIATE_JEANGALEA:
      case URL.AFFILIATE_FINANCIAL_PROFESSIONAL:
      case URL.AFFILIATE_FREE_BITCON:
      case URL.CONSTANT_TOKEN:
      case URL.FLEX_CRYPTO:
      case URL.CRYPTO_LEND_SIGN_UP:
      case URL.CRYPTO_LEND_TO_EARN:
      case URL.USER_SIGN_UP_BORROW:
        return null;
      case URL.HOME:
      case URL.FLEX:
      case URL.BADGES:
        return CONTENTS.signUp;
      case URL.INVEST:
        return CONTENTS.investSignUp;
      case URL.CRYPTO_LOAN:
        return CONTENTS.borrowSignUp;
      case URL.LENDING_LOTTERY:
        return CONTENTS.lendingLotterySignUp;
      default:
        return CONTENTS.signUp;
    }
  }

  switch (pathname) {
    case URL.HOME:
    case URL.DEPOSIT:
    case URL.DEPOSIT_FIAT:
    case URL.DEPOSIT_CRYPTO:
    case URL.FLEX:
    case URL.BADGES:
      return CONTENTS.deposit;
    case URL.INVEST:
      return CONTENTS.invest;
    case URL.CRYPTO_LOAN:
      return CONTENTS.borrow;
    case URL.TRANSFER:
      return CONTENTS.referralFromTransfer;
    case URL.TRANSFER_CRYPTO:
      return CONTENTS.referralFromTransferCrypto;
    case URL.WITHDRAW:
      return CONTENTS.referralFromWithdraw;
    default:
      return null;
  }
};

const StyledSection = styled(BlockSection)`
  background-color: #F8FAFD;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    > img {
      max-height: 250px;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
  .content {
    max-width: 350px;
    .h3 {
      font-size: 30px;
      line-height: 36px;
      font-weight: normal;
      color: black;
    }
    button {
      margin-top: 30px;
      font-size: 15px;
      font-weight: bold;
      width: 200px;
      height: 50px;
      border-radius: 25px;
    }
  }
`;

const CTA = (props) => {
  const { history, isAuth, email } = props;
  const content = getContent(history?.location?.pathname, isAuth);

  return null;

  return content ? (
    <StyledSection>
      <div className="content">
        {content.title}
        {content.button && (
          <Button
            id={content.id && content.id}
            onClick={content.buttonAction.bind(null, history, props, content.gaInfo ? {...content.gaInfo, email} : null)}
          >
            {content.button}
          </Button>
        )}
      </div>
      <img alt="" className="lazy-image" data-src={BgCta} />
    </StyledSection>
  ) : null;
};

const mapState = state => ({
  isAuth: !!state?.auth?.token,
  isAffiliate: state.auth.profile?.affiliateUser,
  email: state.auth.profile?.email || '',
});

export default compose(
  withRouter,
  connect(mapState),
)(CTA);
