import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { URL } from 'src/resources/constants/url';
import { ListLinkContainerStyled } from './styled';

const StyledContainer = styled(ListLinkContainerStyled)`
  grid-column-start: 2;
  grid-column-end: 4;
  .content {
    display: flex;
    > div {
      flex: 1;
    }
  }
`;

const MainPages = (props) => {
  return (
    <StyledContainer>
      <div className="content">
        <div>
          <h3 className="title">Invest</h3>
          <div className="link">
            <Link to={URL.DEPOSIT_FIAT}>Deposit USD</Link>
          </div>
          <div className="link">
            <Link to={URL.INVEST}>Lend USD</Link>
          </div>
          <div className="link">
            <Link to={URL.DEPOSIT_CRYPTO}>Deposit Crypto</Link>
          </div>
          <div className="link">
            <Link to={URL.FLEX_CRYPTO}>Lend Crypto</Link>
          </div>
          {/* <h3 className="title" style={{ marginTop: 26 }}>Transfer</h3>
          <div className="link">
            <Link to={URL.TRANSFER}>Transfer USD</Link>
          </div> */}
        </div>
        <div>
          <h3 className="title">Borrow</h3>
          <div className="link">
            <Link to={URL.CRYPTO_LOAN}>Crypto-backed Loans</Link>
          </div>
          <div className="link">
            <Link to={URL.CRYPTO_SHORT}>Short Selling</Link>
          </div>
          <div className="link">
            <Link to={URL.CRYPTO_FUTURE}>Predict Crypto Prices</Link>
          </div>
          <h3 className="title" style={{ marginTop: 27 }}>Withdraw</h3>
          <div className="link">
            <Link to={URL.WITHDRAW}>Withdraw USD</Link>
          </div>
          <div className="link">
            <Link to={URL.TRANSFER_CRYPTO}>Withdraw Crypto</Link>
          </div>
          {/* <div className="link">
            <Link to={URL.GIFT_CARDS}>Gift Cards</Link>
          </div> */}
        </div>
      </div>
    </StyledContainer>
  );
};

export default connect(
  state => ({
    email: state.auth.profile?.email || '',
  }), {}) (MainPages);

