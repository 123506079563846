import React from 'react';
import styled from 'styled-components';

import GooglePlayDownload from 'src/assets/images/footer/google-play-download.svg';
import AppStoreDownload from 'src/assets/images/footer/app-store-download.svg';
import ga, { events } from 'src/services/ga';
import localStore from 'src/services/localStore';
import { USER } from 'src/resources/constants/user';
import { getDownloadLink, trackIpInfo } from 'src/services/ga/ga-utils';
import { connect } from 'react-redux';

const StyledContainer = styled.div`
  display: flex;
  a:not(:last-child) {
    margin-right: 5px;
  }
  img {
    margin-bottom: 0;
    border-radius: 8px;
  }
`;

const DownloadApp = ({trackIpInfo}) => {
  const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
  return null;
  return (
    <StyledContainer>
      <a rel="nofollow" href={getDownloadLink('ios', events.action.downloadAppFooterIos)} target='_blank' onClick={() => {
        ga(events.category.downloadApp, events.action.downloadAppFooterIos, email);
        trackIpInfo(events.category.downloadApp, events.action.downloadAppFooterIos);
      }}>
        <img className="lazy-image" data-src={AppStoreDownload} alt="app-store-download" width={150} height={44} />
      </a>
      <a rel="nofollow" href={getDownloadLink('android', events.action.downloadAppFooterAndroid)} target='_blank' onClick={() => {
        ga(events.category.downloadApp, events.action.downloadAppFooterAndroid, email);
        trackIpInfo(events.category.downloadApp, events.action.downloadAppFooterAndroid);
      }}>
        <img className="lazy-image" data-src={GooglePlayDownload} alt="google-play-download" width={150} height={44} />
      </a>
    </StyledContainer>
  );
};

const mapDispatch = {
  trackIpInfo
};

export default connect(null, mapDispatch)(DownloadApp);