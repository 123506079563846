import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { LabelLang } from 'src/lang/components';
import { URL } from 'src/resources/constants/url';
import { ListLinkContainerStyled } from './styled';
import ga, { events } from 'src/services/ga';
import { useSelector } from 'react-redux';

const getIntlKey = name => `footer.${name}`;

const About = () => {
  const profile = useSelector(state => state.auth.profile);

  return (
    <ListLinkContainerStyled>
      <h3 className="title">About us</h3>
      <div className="content">
        {/* <div className="link">
          <Link to={URL.ABOUT_US}><LabelLang id="headerBar.ourStory" /></Link>
        </div> */}
        <div className="link">
          <Link to={URL.TESTIMONIALS}><LabelLang id="headerBar.testimonial" /></Link>
        </div>
        {/* <div className="link">
          <Link to={URL.STATISTICS}>Our results so far</Link>
        </div> */}
        <div className="link">
          <Link to={URL.FEE_AND_SERVICES_TIMES}><LabelLang id={getIntlKey('prices')} /></Link>
        </div>
        <div className="link">
          <Link to={URL.WITHDRAW_FEE}>Collateral rules</Link>
        </div>
        <div className="link">
          <Link to={URL.LO_SECURITY_RISK}>Security & risk</Link>
        </div>
        <div className="link">
          <Link
            to={URL.REFERRAL}
            onClick={() => {ga(events.category.referralClick, events.action.referralClickFooterDesktop, profile?.email);}}
          >Share & Earn</Link>
        </div>
        <div className="link">
          <Link to={URL.AFFILIATES}>Affiliates</Link>
        </div>
        <div className="link">
          <a href={`${URL.FORUM}/general?page=1`}>Conversations</a>
        </div>
        {/* <div className="link">
          <a href={URL.BLOG_WORDPRESS}><LabelLang id={getIntlKey('blog')} /></a>
        </div> */}
        <div className="link">
          <a href="https://www.myconstant.com/files/MyConstant-Tokenomics.pdf" target="_blank">MCT Tokenomic</a>
        </div>
      </div>
    </ListLinkContainerStyled>
  );
};

export default injectIntl(About);
