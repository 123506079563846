import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import icEmail from 'src/assets/images/icon/email.svg';
import support from 'src/assets/images/icon/support.svg';
import telegram from 'src/assets/images/icon/telegram.svg';
import logoFooter from 'src/assets/images/logo.svg';
import logoFooterDark from 'src/assets/images/logo-dark.svg';
import SocialMedia from 'src/components/screenContainer/footer/socialMedia';
import { HtmlLang, LabelLang } from 'src/lang/components';
import { URL } from 'src/resources/constants/url';
import ga, { events } from 'src/services/ga';
import AppIcon from 'src/components/appIcon';
import { loadLazyImage } from 'src/utils/images';

import icArrowNext from './assets/ic_arrow_next.svg';
import icFlagUS from './assets/ic_flags_us.svg';
import Content from '../footer/content';
import DownloadApp from '../footer/downloadApp';
import { FooterStyled, StyledMenuContainer, StyledMenuHeader, StyledIconArrow, StyledContactUs, AppIconWrapper, StyledMenuItem, StyledSupport } from './styled';

const getIntlKey = name => `footer.${name}`;

const FooterMobile = ({ theme }) => {
  const email = useSelector(state => state.auth.profile?.email || '');
  const topBannerType = useSelector(state => state.topBanner.type);
  const [activeId, setActiveId] = useState('');

  useEffect(() => {
    loadLazyImage();
  }, []);

  const toggleActive = id => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return (
    <FooterStyled>
      <div className="branding">
        <AppIconWrapper>
          <AppIcon>
            <img className="lazy-image" data-src={theme === 'dark' ? logoFooterDark : logoFooter} width={150} height={25} alt="MyConstant logo" />
          </AppIcon>
          <div>Experience multi-market <span className="orange">peer-to-peer</span> lending.</div>
        </AppIconWrapper>
        {/* <div className="reward">
          <span className="semiBold">
            <i className="far fa-copyright" />
            <LabelLang id={getIntlKey('company.name')} />
          </span>
          <LabelLang id={getIntlKey('company.address1')} />
          <br />
          <LabelLang id={getIntlKey('company.address2')} />
        </div> */}
      </div>
      <StyledMenuContainer>
        <Accordion defaultActiveKey="2">
          <Accordion.Item className="StyledMenuItem" eventKey="0">
            <Accordion.Button
              onClick={() => toggleActive('0')}
              as={StyledMenuHeader}
              variant="link"
              eventKey="0"
            >
              <div className="title">Our Products</div>
              <StyledIconArrow
                isActive={activeId === '0'}
                src={icArrowNext}
                alt="myconstant.com - icon-next"
              />
            </Accordion.Button>
            <Accordion.Body className="StyledMenuBody">
              <>
                <Link to={URL.DEPOSIT_FIAT}>Deposit USD</Link>
                <Link to={URL.INVEST}>Lend USD</Link>
                <Link to={URL.DEPOSIT_CRYPTO}>Deposit Crypto</Link>
                <Link to={URL.FLEX_CRYPTO}>Lend Crypto</Link>
                <Link to={URL.CRYPTO_LOAN}>Crypto-backed Loans</Link>
                <Link to={URL.WITHDRAW}>Short Selling</Link>
                <Link to={URL.CRYPTO_FUTURE}>Predict Crypto Prices</Link>
{/*                 <Link to={URL.TRANSFER}>Transfer USD</Link> */}
                <Link to={URL.WITHDRAW}>Withdraw USD</Link>
                <Link to={URL.TRANSFER_CRYPTO}>Withdraw Crypto</Link>
                {/* <Link to={URL.GIFT_CARDS}>Gift Cards</Link> */}
              </>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="StyledMenuItem" eventKey="1">
            <Accordion.Button
              onClick={() => toggleActive('1')}
              as={StyledMenuHeader}
              variant="link"
              eventKey="1"
            >
              <div className="title">About Us</div>
              <StyledIconArrow
                isActive={activeId === '1'}
                src={icArrowNext}
                alt="myconstant.com - icon-next"
              />
            </Accordion.Button>
            <Accordion.Body className="StyledMenuBody">
              <>
                {/* <Link to={URL.ABOUT_US}><LabelLang id="headerBar.ourStory" /></Link> */}
                <Link to={URL.TESTIMONIALS}><LabelLang id="headerBar.testimonial" /></Link>
                {/* <Link to={URL.STATISTICS}>Our results so far</Link> */}
                <Link to={URL.FEE_AND_SERVICES_TIMES}><LabelLang id={getIntlKey('prices')} /></Link>
                <Link to={URL.WITHDRAW_FEE}>Collateral rules</Link>
                <Link to={URL.LO_SECURITY_RISK}>Security & risk</Link>
                <Link to={URL.REFERRAL}>Share & Earn</Link>
                <Link to={URL.AFFILIATES}>Affiliates</Link>
                <a href={`${URL.FORUM}/general?page=1`}>Conversations</a>
                {/* <a target="_blank" href={URL.BLOG_WORDPRESS}><LabelLang id={getIntlKey('blog')} /></a> */}
                <a href="https://www.myconstant.com/files/MyConstant-Tokenomics.pdf" target="_blank">MCT Tokenomic</a>
              </>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="StyledMenuItem" eventKey="2">
            <Accordion.Button
              onClick={() => toggleActive('2')}
              as={StyledMenuHeader}
              variant="link"
              eventKey="2"
            >
              <div className="title">Our Programs</div>
              <StyledIconArrow
                isActive={activeId === '2'}
                src={icArrowNext}
                alt="myconstant.com - icon-next"
              />
            </Accordion.Button>
            <Accordion.Body className="StyledMenuBody">
              <>
                <Link to={URL.MEMBERSHIP}><LabelLang id="user.memberShip" /></Link>
                <Link to={URL.BADGES}><LabelLang id={getIntlKey('badges')} /></Link>
                <Link
                  id="referral_footer_desktop"
                  to={URL.REFERRAL}
                  onClick={() => {
                    ga(
                      events.category.referralClick,
                      events.action.referralClickFooterMobile,
                      email,
                    );
                  }}
                >
                  <LabelLang id={getIntlKey('referral')} />
                </Link>
                <Link to={URL.AFFILIATES}>Affiliates</Link>
              </>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* <StyledSupport>
          <div>
            <div className="title"><LabelLang id={getIntlKey('support')} /></div>
            <SocialMedia />
          </div>
          <div>
            <div className="link">
              <Link to={URL.LIVE_CHAT}>
                <img src={telegram} alt="telegram" />
                Live chat
              </Link>
              <a href={URL.EMAIL_SUPPORT}>
                <img src={icEmail} alt="email" />
                <LabelLang id={getIntlKey('social.email.title')} />
              </a>
              <Link to={URL.SUPPORT_CENTER}>
                <img src={support} alt="support center" />
                <LabelLang id={getIntlKey('supportCenter')} />
              </Link>
            </div>
            <div>
              <StyledContactUs theme={theme} href={`tel:${getIntlKey('phone.us.phone')}`}>
                <div><LabelLang id={getIntlKey('contactUs')} /></div>
                <div className="phone">
                  <img src={icFlagUS} alt="myconstant.com - flag US" />
                  <LabelLang id={getIntlKey('phone.us.phone')} />
                </div>
                <HtmlLang className="time" id={getIntlKey('phone.us.timeMobile')} />
              </StyledContactUs>
            </div>
          </div>
        </StyledSupport> */}
      </StyledMenuContainer>
      <div className="termAndAgreement">
        <div>
          <LabelLang
            id="footer.copyright"
            values={{
              privacy: (
                <Link to={`${URL.AGREEMENTS}?view=privacy`}>
                  <LabelLang id={getIntlKey('privacy')} />
                </Link>
              ),
              term: (
                <Link to={`${URL.AGREEMENTS}?view=termOfService`}>
                  <LabelLang id={getIntlKey('termOfService')} />
                </Link>
              ),
            }}
          />
        </div>
        <div>
          <LabelLang
            id="footer.copyright2"
            values={{
              term: (
                <Link to={`${URL.AGREEMENTS}?view=AMLPolicy`}>
                  <LabelLang id={getIntlKey('AMLPolicy')} />
                </Link>
              ),
            }}
          />
        </div>
        <div>
          <LabelLang
            id="footer.copyright2"
            values={{
              term: (
                <Link to={`${URL.AGREEMENTS}?view=serviceAgreement`}>
                  <LabelLang id={getIntlKey('serviceAgreement')} />
                </Link>
              ),
            }}
          />
        </div>
        <div>
          <LabelLang
            id="footer.copyright2"
            values={{
              term: (
                <a rel="nofollow" href={URL.KYC_GUIDELINE} target="_blank">
                  <LabelLang id={getIntlKey('kycGuidelines')} />
                </a>
              ),
            }}
          />
        </div>
      </div>
      <div className={`mb-footer-content ${topBannerType ? 'hasBanner' : ''}`}>
        <DownloadApp />
        <Content />
      </div>
    </FooterStyled>
  );
};

export default React.memo(FooterMobile);
