import React from 'react';
import {LabelLang} from 'src/lang/components';
import email from 'src/assets/images/icon/email.svg';
import support from 'src/assets/images/icon/support.svg';
import telegram from 'src/assets/images/icon/telegram.svg';
import {URL} from 'src/resources/constants/url';

import {injectIntl} from 'react-intl';
import { ListLinkContainerStyled } from './styled';

const getIntlKey = name => `footer.${name}`;

const Supports = () => {
  return null;
  return (
    <ListLinkContainerStyled>
      <h3 className="title">
        <LabelLang id="footer.support" />
      </h3>
      <div className="content">
        {/* <div className="link">
          <img src={telegram} alt='telegram' />
          <a href={URL.LIVE_CHAT} target='_blank'>Live chat</a>
        </div> */}
        <div className="link">
          <img src={email} alt='email' />
          <a href={URL.EMAIL_SUPPORT} target='_blank'><LabelLang id={getIntlKey('social.email.title')} /></a>
        </div>
        <div className="link">
          <img src={support} alt='support center' />
          <a href={URL.SUPPORT_CENTER} target='_blank'><LabelLang id={getIntlKey('supportCenter')} /></a>
        </div>
      </div>
    </ListLinkContainerStyled>
  );
};

export default injectIntl(Supports);
