import React from 'react';
import styled from 'styled-components';

const AppIconStyled = styled.div`
  position: relative;
  padding-left: 0px;
  
  > img {
    height: ${props => props.height || 25}px;
    width: ${props => props.width || 150}px;
  }
`;

const AppIcon = ({ height, children }) => {
  return (
    <AppIconStyled height={height} className="appIcon">
      {children}
    </AppIconStyled>
  );
};

export default AppIcon;
