import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { LabelLang } from 'src/lang/components';
import { URL } from 'src/resources/constants/url';
import DownloadApp from './downloadApp';
import SocialMedia from './socialMedia';

const getIntlKey = name => `footer.${name}`;

const InformationStyled = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid var(--border) ;
  border-bottom: 1px solid var(--border);
  padding: 20px 0;
  .terms {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 50%;
    font-size: 9px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 30px;
    margin-right: 55px;
    a {
      font-weight: 500;
    }
    > div {
      margin-right: 30px;
    }
  }
`;

const Information = (props) => {
  return (
    <InformationStyled>
      <div className="terms">
        <div>
          <LabelLang
            id="footer.copyright"
            values={{
              privacy: <Link to={`${URL.AGREEMENTS}?view=privacy`}><LabelLang id={getIntlKey('privacy')} /></Link>,
              term: <Link to={`${URL.AGREEMENTS}?view=termOfService`}><LabelLang id={getIntlKey('termOfService')} /></Link>,
            }}
          />
        </div>
        <div>
          <LabelLang
            id="footer.copyright2"
            values={{
              term: <Link to={`${URL.AGREEMENTS}?view=serviceAgreement`}><LabelLang id={getIntlKey('serviceAgreement')} /></Link>,
            }}
          />
        </div>
        <div>
          <LabelLang
            id="footer.copyright2"
            values={{
              term: <Link to={`${URL.AGREEMENTS}?view=AMLPolicy`}><LabelLang id={getIntlKey('AMLPolicy')} /></Link>,
            }}
          />
        </div>
        <div>
          <LabelLang
            id="footer.copyright2"
            values={{
              term: <a rel="nofollow" href={URL.KYC_GUIDELINE} target="_blank"><LabelLang id={getIntlKey('kycGuidelines')} /></a>,
            }}
          />
        </div>
      </div>
      {/* <DownloadApp />
      <SocialMedia /> */}
    </InformationStyled>
  );
};

export default Information;
