import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';

import { WrapperLang } from 'src/lang/components';
import style from './styles.scss';
import facebook from './img/ic_facebook.svg';
import twitter from './img/ic_twitter.svg';
import medium from './img/ic_medium.svg';
import youtube from './img/ic_youtube.svg';
import discord from './img/ic_discord.svg';

const ICONS = {
  fb: facebook,
  tw: twitter,
  yt: youtube,
  md: medium,
  discord: discord,
};

const getIntlKey = name => `footer.${name}`;

const SocialMediaStyled = styled.div`
  display: flex;
  margin-left: 120px;
  > div {
    margin-right: 10px;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba(90, 113, 132, 0.1);
    width: 30px;
    height: 30px;
  }
  
  img {
    width: 50%;
    height: 50%;
  }

  @media screen and (max-width: 768px) {
    margin-left: unset;
  }
`;

const SocialMedia = (props) => {
  return (
    <SocialMediaStyled className={cx(style.socialMedia)}>
      {
        ['fb', 'tw', 'md', 'yt', 'discord'].map(item => {
          const keyTitle = `social.${item}.title`;
          const keyLink = `social.${item}.link`;
          const icon = ICONS[item];
          return (
            <div key={item} className={style.link}>
              <WrapperLang>
                {ts => {
                  return (
                    <a href={`${ts(getIntlKey(keyLink))}`} rel='noopener noreferrer nofollow' target='_blank'>
                      <img src={icon} alt={`${ts(getIntlKey(keyTitle))}`} />
                    </a>
                  );
                }}
              </WrapperLang>
            </div>
          );
        })
      }
    </SocialMediaStyled>
  );
};

export default SocialMedia;
