import styled, { css } from 'styled-components';

export const FooterContainerStyled = styled.div`
  display: grid;
  grid-template-columns: 1.5fr repeat(4, 1fr);
  padding-bottom: 20px;
  ${props => props.isLockRedirect && css`
    pointer-events: none;
  `}

  a {
    cursor: pointer;
    font-size: inherit;
    color: inherit;
    line-height: 30px;

    &:hover {
      color: inherit;
    }
  }
  .branding {
    display: flex;
    flex-direction: column;
  }

  .logo {
    font-size: 22px !important;
    font-weight: 400 !important;
    color: #FFFFFF;
    line-height: 24px;

    span {
      font-size: inherit;
      font-weight: inherit;
    }
    img {
      margin-bottom: 30px;
    }
  }
  .reward {
    display: flex;
    align-items: center;
    margin-right: 50px;
    line-height: 20px;
    letter-spacing: 0.3px;
    font-size: 11px;
    margin-top: 170px;
    .name {
      font-weight: 500;
      font-size: 13px;
    }
  }
  .contactUsItem {
    @media screen and (max-width: 768px) {
      grid-column: 1 / span 2;
    }
  }
  .socialMedia {
    display: flex;
    > div {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    padding: 20px;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 10px 20px;
    .title {
      margin-bottom: 0px;
    }
  }
`;

export const ListLinkContainerStyled = styled.div`
  width: 100%;
  .title {
    font-weight: bold;
    font-size: 16px;
    color: var(--text-primary);
    line-height: 24px;
    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }
  .link {
    font-size: 15px;
    line-height: 30px;
    color: inherit;
    img {
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
  }
  .container {
    background: white;
    width: 100%;
  }
  .time {
    font-size: 13px;
    color: inherit;
    margin-bottom: 5px;
  }
  .downloadApp {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    > a {
      margin-bottom: 5px;
    }
  }
`;